import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  UsergroupAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import Table, { TableColumn } from '../../components/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import { createFormatProvider } from '../../services/providers/factories';
import CreateUserModal from './components/createUserModal/modal'
import EditUserModal from './components/modal';
import ViewUserModal from './components/viewUserModal';
import FilterForm from './components/FilterForm';
import { IFilterUserProps, IUsuariosProps } from './types';
import { formatterPhoneNumber } from '../../components/Masks';
import {
  getAllUsers,
  deleteUser,
  onUpdateUserModalStatus,
  onViewUserModalStatus,
  openCreateUserModal,
  CreateUser,
  UpdateUser,
  onCreateNewUser,
  linkUser,
  getUserInformation,
  openUserEditModal,
  desvincularUsuario,
} from './actions';
import {
  Button,
  Container, ResponsiveTable,
  TableContainer,
  ResponsiveRow,
  ResponsiveColumn,
  ResponsiveTableTitle,
} from './styles';
import { T2 } from '../../styles/titles';
import { ICreateNewUserDto, IUpdateUserDto } from '../../global/dataTransferObjects/user';
import { IUserFormValues } from './components/createUserModal/actions/types';
import Modal from '../../components/Modal';
import LinkUserForm from './components/linkUserFilter';
import { IFormValues } from './components/linkUserFilter/types';
import useGetMe from "../../hooks/useCurrentUser";

const Usuarios: React.FC<IUsuariosProps> = ({ doLogout }: IUsuariosProps) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [userIdToLink, setUserIdToLink] = useState<string>('');
  const user = useGetMe();

  const fetchAllUsers = useCallback(() => getAllUsers(0, 5000)(dispatch), []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const handleCloseUpdateModalStatus = (status: boolean) => onUpdateUserModalStatus(status)(dispatch);

  const handleCloseCreateModalStatus = (status: boolean) => onCreateNewUser(status)(dispatch);

  const handleViewModalStatus = (status: boolean) => onViewUserModalStatus(status)(dispatch);

  const handleOpenCreateUserModal = () => {
    openCreateUserModal()(dispatch)
  };

  const handleOpenEditUserModal = (user: IUpdateUserDto) => {
    openUserEditModal({ ...user })(dispatch)
  };

  const fetchUserInformation = useCallback((userId: string) => getUserInformation(userId)(dispatch), []);

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const formatUserType = (user: number) => {
    if (user === 1) {
      return 'Pessoa Física';
    }
    if (user === 2) {
      return 'Pessoa Jurídica';
    }
    if (user === 3) {
      return 'Administrador'
    }
    if (user === 4) {
      return 'Antecipadora'
    }
    if (user === 6) {
      return 'Comercial'
    }
    if (user === 7) {
      return 'Parceiro'
    }
    return '';
  };

  const responsiveFormartUserType = (user: number):string => {
    switch (user) {
      case 1: return 'PF';
      case 2: return 'PJ';
      case 3: return 'Administrador';
      case 4: return 'Antecipadora';
      case 6: return 'Comercial';
      case 7: return 'Parceiro'
      default: return '';
    }
  }

  const fetchAllUsersPagination = (initialPage: number, finalPage: number) => {
    getAllUsers(initialPage, finalPage, filterOrder)(dispatch);
  };

  const handleDelete = async (id: string) => {
    await deleteUser(id)(dispatch);
    fetchAllUsers();
  };

  const handleDesvincularUsuario = async (id: string) => {
    await desvincularUsuario(id)(dispatch);
    fetchAllUsers();
  };

  const onModalCreateFormSubmit = async (values: IUserFormValues): Promise<void> => {
    const userDto: ICreateNewUserDto = {
      ...values,
      endereco: {
        cep: values.cep,
        logradouro: values.logradouro,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        uf: values.uf,
      },
    }
    await CreateUser(userDto)(dispatch);
    fetchAllUsers();
  };

  const onModalFormSubmit = async (userUpdated: IUpdateUserDto) => {
    const result = await UpdateUser(userUpdated.id, userUpdated)(dispatch);
    if (result) {
      handleOpenEditUserModal(userUpdated);
      fetchAllUsers();
      onUpdateUserModalStatus(false)(dispatch);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  }

  const handleUserFilter = async (filter: IFilterUserProps) => {
    setFilterOrder(filter);
    getAllUsers(offSet, pageSize, filter)(dispatch);
  };

  const handleLinkUser = async (values : IFormValues) => {
    linkUser(userIdToLink, values.anticipationId?.value)(dispatch)
    closeModal();
    fetchAllUsers();
  };
  useEffect(() => {
    if (currentPage > 1) {
      fetchAllUsersPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllUsersPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  // Remove the logic for pagination for the responsive table
  const userList = state.users.records.map((user) => ({
    ...user,
    cidade: user.address?.cidade || 'Não Cadastrado',
  }));

  return (
    <Container>
      <Modal
        title="Vincular Usuário"
        centered
        visible={modalVisible}
        onCancel={closeModal}
        width={400}
      >
        <LinkUserForm onSubmit={handleLinkUser} />
      </Modal>
      <LoadingOverlay
        show={state.isViewCreateUserModalOpen || state.isViewUserModalOpen ? false : state.loading}
        relative
      />
      <T2>Usuários</T2>
      <FilterForm
        onFormSubmit={handleUserFilter}
      />
      {user.userType != 6 && (
        <Button onClick={() => handleOpenCreateUserModal()}>Adicionar usuário</Button>
      )}
      <TableContainer>
        <Table
          dataSource={[...state.users.records]}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.users.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="Sobrenome" dataIndex="lastName" key="lastName" />
          <TableColumn title="E-Mail" dataIndex="email" key="email" />
          <TableColumn title="Tipo de usuário" dataIndex="userType" key="userType" render={formatUserType} />
          <TableColumn title="Data de cadastro" dataIndex="createdAt" key="createdAt" render={formattedDate} />
          <TableColumn title="Telefone" dataIndex="phoneNumber" key="phoneNumber" render={formatterPhoneNumber} />
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            render={(_: any, record: any) => (state.users.records.length >= 1 ? (
              <EyeOutlined onClick={() => fetchUserInformation(record.id)} />
            ) : null)}
          />
          { user.userType != 6 &&(
            <TableColumn
              title="Editar"
              dataIndex="editar"
              key="editar"
              render={(i: any, e: any) => (state.users.records.length >= 1 ? (
                <Popconfirm
                  title="Deseja realmente editar?"
                  cancelText="Cancelar"
                  okText="Editar"
                  onConfirm={() => handleOpenEditUserModal(e)}
                >
              <EditOutlined/>
                </Popconfirm>
              ) : null)}
            />
            )}
          { user.userType != 6 &&(
            <TableColumn
              title="Excluir"
              dataIndex="excluir"
              key="excluir"
              render={(i: any, e: any) => (state.users.records.length >= 1 ? (
                <Popconfirm
                  title="Deseja realmente excluir?"
                  cancelText="Cancelar"
                  okText="Excluir"
                  onConfirm={() => handleDelete(e.id)}
                >
                  <DeleteOutlined />
                </Popconfirm>
              ) : null)}
            />
          )}
          { user.userType != 6 &&(
            <TableColumn
              title="Vincular usuário"
              dataIndex="vincularUsuario"
              key="vincularUsuario"
              render={(i: any, e: any) => (e.travaAntecipadora || e.userType === 3 || e.userType === 4 ? (
                <UserDeleteOutlined
                  style={{ color: 'red' }}
                  onClick={() => handleDesvincularUsuario(e.id)}
                />
              ) : (
                <UsergroupAddOutlined
                  style={{ color: 'green' }}
                  onClick={() => { setModalVisible(true); setUserIdToLink(e.id) }}
                />
              ))}
            />
          )}
        </Table>
      </TableContainer>

      <ResponsiveTable>
        <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
          <ResponsiveTableTitle>Nome</ResponsiveTableTitle>
          <ResponsiveTableTitle>Empresa</ResponsiveTableTitle>
          <ResponsiveTableTitle>Tipo</ResponsiveTableTitle>
        </ResponsiveRow>
        {userList.map((user) => (
          <ResponsiveRow
            key={user.id}
            style={{ backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)", cursor: "pointer" }}
            onClick={() => fetchUserInformation(user.id)}
          >
            <ResponsiveColumn>
              <p>{user.name}</p>
            </ResponsiveColumn>
            <ResponsiveColumn>
              <p>{user.companyName}</p>
            </ResponsiveColumn>
            <ResponsiveColumn>
              <p><strong>{responsiveFormartUserType(user.userType)}</strong></p>
            </ResponsiveColumn>
          </ResponsiveRow>
        ))}
      </ResponsiveTable>
      <CreateUserModal
        onFormSubmit={onModalCreateFormSubmit}
        onModalClose={() => handleCloseCreateModalStatus(false)}
        loading={state.loadingModal}
        isVisible={state.isViewCreateUserModalOpen}
      />
      <EditUserModal
        loading={state.loadingModal}
        onFormSubmit={onModalFormSubmit}
        user={state.user}
        isVisible={state.isViewUpdateUserModalOpen}
        onClose={() => handleCloseUpdateModalStatus(false)}
      />
      <ViewUserModal
        user={{ ...state.user }}
        isVisible={state.isViewUserModalOpen}
        onClose={() => handleViewModalStatus(false)}
        doLogout={doLogout}
        loading={state.loading}
      />
    </Container>
  );
};

export default Usuarios;
