import React, {ChangeEvent, FocusEvent, useEffect, useReducer, useState} from 'react';
import {withFormik} from 'formik';
import {toast} from "react-toastify";
import { Row } from 'antd';
import Select from 'antd/lib/select';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  Form,
  Label,
  ValueCard,
  FilterRow,
  FilterContainer, Col,
} from './styles';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../../../global/dataTransferObjects/transacoesTempoReal';
import { FormProps, IFormProps } from '../../types';
import {formatDate, formatDateToDateTime} from '../../../../../utils/formatDate';
import useGetMe from '../../../../../hooks/useCurrentUser'
import {InitialState, Reducer} from "../../actions/reducer";
import {getAllLiveTransactions} from "../../actions";

const FormFilter: React.FC<FormProps> = (props: FormProps) => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const user = useGetMe();
  const {handleSubmit, totals} = props;
  const [bandeira, setBandeira] = useState<string>('');
  const [tipoPagamento, setTipoPagamento] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [acquirerNsu, setAcquirerNsu] = useState<string>('');
  const [acquirer, setAcquirer] = useState<number>(-1);
  const [status, setStatus] = useState<number>(1);
  const [initialDate, setInitialDate] = useState<string | Date>('');
  const [finalDate, setFinalDate] = useState<string | Date>('');
  const [initialDateFilterType, setInitialDateFilterType] = useState<string>('text');
  const [finalDateFilterType, setFinalDateFilterType] = useState<string>('text');
  const [totalParamsState, setTotalParamsState] = useState();
  const [merchantId, setMerchantId] = useState<string>('')
  const [orderNumber, setOrderNumber] = useState<string>('');

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');

    const startOfDay = `${yyyy}-${mm}-${dd}T00:00:00`;
    const endOfDay = `${yyyy}-${mm}-${dd}T23:59:59`;

    props.setFieldValue('initialDate', startOfDay);
    props.setFieldValue('finalDate', endOfDay);
    setInitialDate(startOfDay);
    setFinalDate(endOfDay);
  }, []);

  const formatMoney = (value: number) => {
    const formattedValue = (value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  const getCurrentDateAsString = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  }

  const getValorVendaTotal = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalGross;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getTicket = (): string => {
    if (totals?.totalGross !== undefined && totals?.count !== undefined) {
      let avarageTicket = totals?.totalGross / totals?.count;
      if (isNaN(avarageTicket)) avarageTicket = 0;
      const valorFormatado: string = formatMoney(avarageTicket as number);
      return valorFormatado;
    }
    else {
      const valorFormatado: string = formatMoney(0);
      return valorFormatado;
    }
  }

  const getValorLiquidoTotal = (): string => {
    let valor: number | undefined = 0;
    if (totals !== undefined) valor = totals.totalLiquid;

    if (isNaN(valor)) valor = 0;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getTotalTransacoes = (): number => {
    let valor: number = 0;
    if (totals) valor = totals.count;

    if (isNaN(valor)) valor = 0;
    return valor;
  }
  const handleChangeBandeira = (value: string): void => {
    setBandeira(value);
    props.setFieldValue('bandeira', value);
  };
  const handleChangeTipoPagamento = (value: string): void => {
    setTipoPagamento(value);
    props.setFieldValue('tipoPagamento', value);
  };

  const handleChangeStatus = (value: number): void => {
    setStatus(Number(value));
    props.setFieldValue('status', Number(value));
  };

  const handleBusinessNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBusinessName(event.target.value);
    props.setFieldValue('businessName', event.target.value);
  };

  const handleAcquirerNsuChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAcquirerNsu(event.target.value);
    props.setFieldValue('acquirerNsu', event.target.value);
  };

  const handleMerchantId = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setMerchantId(event.target.value);
    props.setFieldValue('merchantId', event.target.value);
  }

  const handleChangeAcquirer = (value: number): void => {
    setAcquirer(Number(value));
    props.setFieldValue('acquirer', Number(value));
  };

  const handleChangeInitialDate = (event: ChangeEvent<HTMLInputElement>): void => {
    setInitialDate(event.target.value);
    props.setFieldValue('initialDate', event.target.value);
  };

  const handleChangeFinalDate = (event: ChangeEvent<HTMLInputElement>): void => {
    setFinalDate(event.target.value);
    props.setFieldValue('finalDate', event.target.value);
  };

  const handleOrderNumber = (event: ChangeEvent<HTMLInputElement>): void => {
    setOrderNumber(event.target.value);
    props.setFieldValue('orderNumber', event.target.value);
  };

  const handleFocusInitialDateFilter = (event: FocusEvent<HTMLInputElement>): void => {
    setInitialDateFilterType('datetime-local');
    if (!event.target.value) {
      const now: Date = new Date();
      const year: number = now.getFullYear();
      const month: string = String(now.getMonth() + 1).padStart(2, '0');
      const day: string = String(now.getDate()).padStart(2, '0');
      const midnight: string = `${year}-${month}-${day}T00:00:00`;

      event.target.value = midnight;
      setInitialDate(event.target.value);
      props.setFieldValue('initialDate', event.target.value);
    }
  };

  const handleFocusFinalDateFilter = (event: FocusEvent<HTMLInputElement>): void => {
    setFinalDateFilterType('datetime-local');
    if (!event.target.value) {
      const now: Date = new Date();
      const year: number = now.getFullYear();
      const month: string = String(now.getMonth() + 1).padStart(2, '0');
      const day: string = String(now.getDate()).padStart(2, '0');
      const almostMidnight: string = `${year}-${month}-${day}T23:59:59`;

      event.target.value = almostMidnight;
      setFinalDate(event.target.value);
      props.setFieldValue('finalDate', event.target.value);
    }
  };

  return (
    <>
      <ValueCard>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor das vendas: </p>
          <p>{getValorVendaTotal()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor Líquido: </p>
          <p>{getValorLiquidoTotal()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Nº de Transações: </p>
          <p>{getTotalTransacoes()}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Ticket Médio: </p>
          <p>{getTicket()}</p>
        </div>
      </ValueCard>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtrar por:</Label>
        </Row>
        <Row
          gutter={16}
        >
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <input type={initialDateFilterType} onFocus={handleFocusInitialDateFilter} name="initialDate"
                     onChange={handleChangeInitialDate} placeholder="Data inicial" step="1"/>
            </Col>
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <input type={finalDateFilterType} onFocus={handleFocusFinalDateFilter} name="finalDate"
                     onChange={handleChangeFinalDate} placeholder="Data final" step="1"/>
            </Col>
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <Select
                defaultValue=""
                style={{width: '100%'}}
                onChange={handleChangeBandeira}
                options={[
                  {
                    value: '',
                    label: 'Bandeira',
                  },
                  {
                    value: '3',
                    label: 'AMEX',
                  },
                  {
                    value: '171',
                    label: 'ELO',
                  },
                  {
                    value: '9',
                    label: 'HIPERCARD',
                  },
                  {
                    value: '2',
                    label: 'MASTERCARD',
                  },
                  {
                    value: '1',
                    label: 'VISA',
                  },
                  {
                    value: '999',
                    label: 'PIX',
                  },
                ]}
              />
            </Col>
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <Select
                defaultValue=""
                style={{width: '100%'}}
                onChange={handleChangeTipoPagamento}
                options={[
                  {
                    value: '',
                    label: 'Tipo pagamento',
                  },
                  {
                    value: '001',
                    label: 'Crédito à Vista',
                  },
                  {
                    value: '002',
                    label: 'Parcelamento sem Juros',
                  },
                  {
                    value: '003',
                    label: 'Parcelamento com Juros',
                  },
                  {
                    value: '004',
                    label: 'Cancelamento',
                  },
                  {
                    value: '005',
                    label: 'Débito',
                  },
                  {
                    value: '006',
                    label: 'Pré-Autorização',
                  },
                  {
                    value: '099',
                    label: 'Pix',
                  },
                ]}
              />
            </Col>
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <input placeholder="NSU" onChange={handleAcquirerNsuChange}/>
            </Col>
            {(user.userType === 3 || user.userType === 6 || user.userType === 7) && (
              <>
                <Col xs={{ flex: '100%' }}
                     sm={{ flex: '50%' }}
                     md={{ flex: '40%' }}
                     lg={{ flex: '20%' }}
                     xl={{ flex: '10%' }}>
                  <input placeholder="Empresa" onChange={handleBusinessNameChange}/>
                </Col>
                <Col xs={{ flex: '100%' }}
                     sm={{ flex: '50%' }}
                     md={{ flex: '40%' }}
                     lg={{ flex: '20%' }}
                     xl={{ flex: '10%' }}>
                  <input placeholder="Cod. Lojista" onChange={handleMerchantId}/>
                </Col>
              </>
            )}
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <Select
                defaultValue={1}
                style={{width: '100%'}}
                onChange={handleChangeStatus}
                options={[
                  {
                    value: -1,
                    label: 'Status',

                  },
                  {
                    value: 0,
                    label: 'Pendente',
                  },
                  {
                    value: 1,
                    label: 'Confirmada',
                  },
                  {
                    value: 2,
                    label: 'Desfeita',
                  },
                  {
                    value: 3,
                    label: 'Negada',
                  },
                  {
                    value: 4,
                    label: 'Cancelada',
                  },
                ]}
              />
            </Col>
            {user.userType === 3 && (
              <Col xs={{ flex: '100%' }}
                   sm={{ flex: '50%' }}
                   md={{ flex: '40%' }}
                   lg={{ flex: '20%' }}
                   xl={{ flex: '10%' }}>
                <Select
                  defaultValue={-1}
                  style={{width: '100%'}}
                  onChange={handleChangeAcquirer}
                  options={[
                    {
                      value: -1,
                      label: 'Adquirente',
                    },
                    {
                      value: 3,
                      label: 'Adiq',

                    },
                    {
                      value: 10,
                      label: 'Rede',
                    },
                    {
                      value: 99,
                      label: 'Valori',
                    },
                  ]}
                />
              </Col>
            )}
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <input placeholder="Id Pedido" onChange={handleOrderNumber}/>
            </Col>
            <Col xs={{ flex: '100%' }}
                 sm={{ flex: '50%' }}
                 md={{ flex: '40%' }}
                 lg={{ flex: '20%' }}
                 xl={{ flex: '10%' }}>
              <Button style={{background: '#fbb651'}} htmlType="submit">
                Consultar
              </Button>
            </Col>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFormProps, PainelVendasFilterProps>({
  mapPropsToValues: (): PainelVendasFilterProps => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');

    const startOfDay = `${yyyy}-${mm}-${dd}T00:00:00`;
    const endOfDay = `${yyyy}-${mm}-${dd}T23:59:59.999`;

    return {
      initialDate: startOfDay,
      finalDate: endOfDay,
      bandeira: '',
      tipoPagamento: '',
      businessName: '',
      acquirerNsu: '',
      merchantId: '',
      orderNumber: '',
      acquirer: -1,
      status: 1,
    };
  },
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    if (values.initialDate == null || values.finalDate == null) {
      toast.error('Preencha as datas corretamente!')
      return;
    }

    const initialDateValue = values.initialDate;
    const finalDateValue = values.finalDate;
    const dateI = formatDate(initialDateValue);
    const dateF = formatDate(finalDateValue);

    const test = {
      ...values,
      initialDate: dateI,
      finalDate: dateF,
    };
    props.onFormSubmit(test);
  },
})(FormFilter);
